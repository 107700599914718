import React, { useState } from "react";
import "./index.scss";
import { getBase64DataFromUrl } from "../../redux/apis";

function DownloadWithProgressBar({ fileUrl, fileName, clientAccessToken }) {
  const [loading, setloading] = useState(false);

  const handleDownload = () => {
    setloading(true)
    getBase64DataFromUrl(fileUrl, clientAccessToken)
      .then((url) => {
        const link = document.createElement("a");
        link.href = url?.data?.data[0];
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.log("Error : ", error))
      .finally(() => {
        setloading(false)
      });
  }

  return (
    <div className="download-progress">
      {!loading ?
        <span className="download-link-text"
          onClick={() => {
            handleDownload()
          }}>Download</span>
        :
        <div className="custom-progress">
          <div className="custom-progress-value"></div>
        </div>
      }
    </div>

  );
}

export default DownloadWithProgressBar;
